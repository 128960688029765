<script>
  let status = "";
  const handleSubmit = async (data) => {
    status = "Submitting...";
    const formData = new FormData(data.currentTarget);
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const hCaptcha = formData.get("h-captcha-response");

    if (!hCaptcha) {
      status = "Please fill out the captcha field";
      return;
    }

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    });
    const result = await response.json();
    if (result.success) {
      console.log(result);
      status = result.message || "Success";
    }
  };
</script>

<div class="container">
  <h1>Kontaktieren Sie uns!</h1>
  <form on:submit|preventDefault={handleSubmit}>
    <input
      type="hidden"
      name="access_key"
      value="008d3909-b1de-46ec-aa95-910e6b9a1586"
    />
    <input
      type="hidden"
      name="subject"
      value="Neue Kontaktanfrage von B2A Teamchallenge!"
    />
    <input type="hidden" name="from_name" value="Kontaktanfrage" />

    <label for="vorname">Vorname</label>
    <input type="text" name="vorname" required />

    <label for="nachname">Nachname</label>
    <input type="text" name="nachname" required />

    <label for="telefon">Telefonnummer</label>
    <input type="tel" name="telefon" />

    <label for="email">Email</label>
    <input type="email" name="email" required />

    <label for="nachricht">Nachricht</label>
    <textarea name="nachricht" required rows="3" />
    <div class="h-captcha" data-captcha="true" />

    <input type="submit" value="Absenden" />
  </form>
  <div id="status">{status}</div>

  <script src="https://web3forms.com/client/script.js" async defer></script>
</div>

<style>
  .container {
    max-width: 1300px;
    font-size: 1.5rem;
    background-color: #fff;
    margin: 0 auto;
    padding: 80px 20px;
  }

  h1 {
    text-align: center;
    color: rgb(49, 49, 49);
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: 10px;
    color: rgb(49, 49, 49);
  }

  input[type="text"],
  input[type="email"],
  textarea {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  textarea {
    resize: vertical;
    height: 100px;
  }

  input[type="submit"] {
    background-color: rgba(170, 193, 0, 0.6);
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  input[type="submit"]:hover {
    background-color: rgba(109, 124, 0, 0.6);
  }

  #status {
    margin-top: 10px;
    color: rgba(170, 193, 0, 0.6);
  }
</style>
