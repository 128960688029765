<script>
  import { video_is_playing } from '../../tools/stores.js';
  import UILinkButton from "../../ui-elements/UILinkButton.svelte";

  function updateVideoStatus() {
    video_is_playing.update(() => true);
  }
</script>

<div class="component container">
  <div class="row g-0 align-items-center">
    <div class="col-md-6">
      <div class="text-big">Mehr bewegen.</div>
      <div class="text-big">Spaß haben.</div>
      <div class="text-big">Mit der Team Challenge.</div>
      <div class="text-small">Für Alle mit Bildschirmarbeitsplatz.</div>
      <div class="book-now">
        <UILinkButton
          href="#video"
          green
          style="padding: 20px 30px; font-size: 1.25rem; line-height: 1.25rem;"
          on:click={updateVideoStatus}
        >
          Video abspielen
        </UILinkButton>
      </div>
    </div>
    <div class="col-md-6 mt-3 mt-md-0">
      <img
        src="/images/laptop_team_challenge.png"
        alt="Laptop showing the team challenge app"
      />
    </div>
  </div>
</div>

<style>
  .component {
    padding: 0 20px;
    color: white;
  }

  .component .text-big {
    font-size: 50px;
    font-weight: bold;
    line-height: 56px;
  }

  .component .text-small {
    font-size: 18px;
    margin-top: 8px;
  }

  .component img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .component .book-now {
    margin-top: 30px;
    text-transform: uppercase;
  }
</style>
