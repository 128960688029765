<script>
  import { onMount } from "svelte";
  import { video_is_playing } from "../../tools/stores.js";

  let video;

  onMount(async () => {
    video_is_playing.subscribe((isPlaying) => {
      if (isPlaying) {
        video.play();
      } else {
        video.pause();
      }
    });
  });

  function updateVideoStatus() {
    video_is_playing.update(() => false);
  }
</script>

<div id="video" class="component">
  <div class="container">
    <h2>Unser kurzer Film zeigt wie's funktioniert...</h2>
    <video
      src="/videos/b2a_tc.mp4"
      type="video/mp4"
      poster="/images/b2a-tc.png"
      controls
      bind:this={video}
      on:pause={updateVideoStatus}
    />
  </div>
</div>

<style>
  .component {
    padding: 80px 20px;
  }

  .component div {
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    color: rgb(74, 74, 74);
  }

  .component div h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .component div video {
    width: 100%;
  }

  @media (max-width: 700px) {
    .component div {
      width: 80%;
    }
  }
</style>
