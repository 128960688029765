<script>
  export let href = undefined;
  export let white = false;
  export let orange = false;
  export let green = false;
</script>

<span class="ui-link-button" class:white class:orange class:green style={$$props.style}>
  <a href="{href}" on:click><slot></slot></a>
</span>

<style>
  span {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 28.5px;
    cursor: pointer;
    font-size: 16px;
  }

  a {
    color: rgb(245, 166, 35);
    text-decoration: none;
  }

  .white {
    background-color: white;
  }

  .white a {
    color: rgb(74, 74, 74);
  }

  .orange {
    background-color: rgb(245, 166, 35);
  }

  .green {
    background-color: rgb(175, 202, 11);
  }

  .orange a,
  .green a {
    color: white;
  }
</style>